import Navbar from "./Navbar";
import { useParams } from 'react-router-dom';
import SeidenwegeJSON from "../Seidenwege"
import axios from "axios";
import { useState } from "react";
import {ethers} from "ethers";
import env from "react-dotenv";

export default function NFTPage (props) {

const [data, updateData] = useState({});
const [dataFetched, updateDataFetched] = useState(false);
const [message, updateMessage] = useState("");
const [currAddress, updateCurrAddress] = useState("0x");

async function getNFTData(tokenId) {
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    //Pull the deployed contract instance
    let contract = new ethers.Contract(SeidenwegeJSON.address, SeidenwegeJSON.abi, signer)
    const owner = await contract.ownerOf(tokenId)
    //create an NFT Token
    let tokenURI = await contract.tokenURI(tokenId);
    const listedToken = await contract.getListedTokenForId(tokenId);
    if(tokenURI.indexOf("ipfs://")!==-1) tokenURI=env.IPFS_GATEWAY+"/"+tokenURI.substring(7,tokenURI.length)
    let meta = await axios.get(tokenURI);
    meta = meta.data;
    console.log("listedToken",listedToken);

    let item = {
        price: listedToken.price,
        tokenId: tokenId,
        seller: listedToken.seller,
        owner: owner,
        image: meta.image,
        name: meta.name,
        description: meta.description,
    }
    console.log(item);
    updateData(item);
    updateDataFetched(true);
    console.log("address", addr)
    updateCurrAddress(addr);
}

async function buyNFT(tokenId) {
    try {
        const ethers = require("ethers");
        //After adding your Hardhat network to your metamask, this code will get providers and signers
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        //Pull the deployed contract instance
        let contract = new ethers.Contract(SeidenwegeJSON.address, SeidenwegeJSON.abi, signer);
        const salePrice =// ethers.utils.parseUnits(data.price, 'ether')
        updateMessage("Buying the NFT... Please Wait (Upto 5 mins)")
        //run the executeSale function
        let transaction = await contract.executeSale(tokenId, {value:data.price});
        await transaction.wait();

        alert('You successfully bought the NFT!');
        updateMessage("");
    }
    catch(e) {
        alert("NFT Buying error"+e)
    }
}

    const params = useParams();
    const tokenId = params.tokenId;
    if(!dataFetched)
        getNFTData(tokenId);
    console.log("data",data)
    let imageURI = data?.image
    if(imageURI && imageURI.indexOf("ipfs://")!==-1) imageURI=env.IPFS_GATEWAY+"/"+imageURI.substring(7,imageURI.length)
    // if(imageURI && imageURI.indexOf("ipfs://")!==-1) imageURI="http://localhost:8081/ipfs/"+imageURI.substring(7,imageURI.length)
    return(
        <div style={{"min-height":"100vh"}}>
            <Navbar></Navbar>
            <div className="flex ml-20 mt-20">
                <img src={imageURI} alt="" className="w-2/5" />
                <div className="text-xl ml-20 space-y-8 text-white shadow-2xl rounded-lg border-2 p-5">
                    <div>
                        Name: {data.name}
                    </div>
                    <div>
                        Description: {data.description}
                    </div>
                    <div>
                        Price: <span className="">{(data.price?ethers.utils.formatEther(data.price):'')} {env?.BUY_NFT_CURRENCY}</span>
                    </div>
                    <div>
                        Owner: <span className="text-sm">{data.owner}</span>
                    </div>
                    <div>
                        Seller: <span className="text-sm">{data.seller}</span>
                    </div>
                    <div>
                    { currAddress == data.owner || currAddress == data.seller ?
                        <div className="text-emerald-700">You are the owner of this NFT</div>
                        : <button className="enableEthereumButton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={() => buyNFT(tokenId)}>Buy this NFT</button> 
                    }
                    
                    <div className="text-green text-center mt-3">{message}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}