import { Link } from "react-router-dom";
import env from "react-dotenv";

function NFTTile (data) {
    const newTo = {
        pathname:"/nft/"+data.data.tokenId
    }
    let imageURI = data?.data.image
    if(data && data?.data?.image && imageURI.indexOf("ipfs://")!==-1) imageURI=env.IPFS_GATEWAY+"/"+imageURI.substring(7,imageURI.length)

    return (
        <Link to={newTo}>
            <div className="border-2 ml-12 mt-5 mb-12 flex flex-col items-center rounded-lg w-48 md:w-72 shadow-2xl">
                <img src={imageURI} alt="" className="w-72 h-80 rounded-lg object-cover" />
                <div className= "text-white w-full p-2 bg-gradient-to-t from-[#454545] to-transparent rounded-lg pt-5 -mt-20">
                    <strong className="text-xl">{data.data.name}</strong>
                    <p className="display-inline">
                        {data.data.description}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default NFTTile;
