import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { utils,ethers } from 'ethers';

function Navbar() {

const [connected, setConnected] = useState(false);
const location = useLocation();
const [currAddress, updateAddress] = useState('0x');

async function getAddress() {

  const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
  const signer = provider.getSigner();
  await provider.send("eth_requestAccounts", []);
  // const account=(await provider.listAccounts())[0]
 const addr = await signer.getAddress();
 updateAddress(addr);
 return addr
}

function updateButton() {
  const ethereumButton = document.querySelector('.enableEthereumButton');
  ethereumButton.textContent = "Connected";
  ethereumButton.classList.remove("hover:bg-blue-70");
  ethereumButton.classList.remove("bg-blue-500");
  ethereumButton.classList.add("hover:bg-green-70");
  ethereumButton.classList.add("bg-green-500");
}

async function connectWebsite() {
  // await window.ethereum.enable()
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    if(chainId !== '0x31337')
    {
      const networkMap = {
        POLYGON_MAINNET: {
          chainId: utils.hexValue(137), // '0x89'
          chainName: "Matic(Polygon) Mainnet",
          nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
          rpcUrls: ["https://polygon-rpc.com"],
          blockExplorerUrls: ["https://www.polygonscan.com/"],
        },
        MUMBAI_TESTNET: {
          chainId: utils.hexValue(80001), // '0x13881'
          chainName: "Matic(Polygon) Mumbai Testnet",
          nativeCurrency: { name: "tMATIC", symbol: "MATIC  ", decimals: 18 },
          rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
          blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        },
        HARDHAT: {
          chainId: utils.hexValue(31337), // '0x13881'
          chainName: "HARDHAT",
          nativeCurrency: { name: "tETH", symbol: "tETH", decimals: 18 },
          rpcUrls: ["http://127.0.0.1:8545/"],
          blockExplorerUrls: null,
        },
      };

      try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: utils.hexValue(80001) }],
            // params: [{ chainId: utils.hexValue(31337) }],
         })
        }
        catch(ex){
        console.log("trying to add blockchain to wallet",ex)
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkMap.MUMBAI_TESTNET],
        });
    }
      await requestAccount()
    }
}
  const requestAccount = async () => {
    // await window.ethereum.request({ method: 'eth_requestAccounts' },[])
    updateButton();
    getAddress();
    window.location.replace(location.pathname)
  }

  useEffect(() => {
    if (connected === false) {
      let val = window.ethereum.isConnected();
      if (val) {
        getAddress();
        setConnected(val);
        updateButton();
      }
      //TODO what is that?
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.replace(location.pathname)
      })
    }
  });
    return (
      <div className="">
        <nav className="w-screen">
          <ul className='flex items-end justify-between py-3 bg-transparent text-white pr-5'>
          <li className='flex items-end ml-5 pb-2'>
            <Link to="/">
            <div className='inline-block font-bold text-xl ml-2'>
              Seidenwege Marketplace
            </div>
            </Link>
          </li>
          <li className='w-2/6'>
            <ul className='lg:flex justify-between font-bold mr-10 text-lg'>
              {location.pathname === "/" ? 
              <li className='border-b-2 hover:pb-0 p-2'>
                <Link to="/">All Seidenwege NFTs</Link>
              </li>
              :
              <li className='hover:border-b-2 hover:pb-0 p-2'>
                <Link to="/">All Seidenwege NFTs</Link>
              </li>              
              }
              {/*{location.pathname === "/sellNFT" ? */}
              {/*<li className='border-b-2 hover:pb-0 p-2'>*/}
              {/*  <Link to="/sellNFT">create NFT</Link>*/}
              {/*</li>*/}
              {/*:*/}
              {/*<li className='hover:border-b-2 hover:pb-0 p-2'>*/}
              {/*  <Link to="/sellNFT">create NFT</Link>*/}
              {/*</li>              */}
              {/*}              */}
              {location.pathname === "/profile" ? 
              <li className='border-b-2 hover:pb-0 p-2'>
                <Link to="/profile">My NFTs</Link>
              </li>
              :
              <li className='hover:border-b-2 hover:pb-0 p-2'>
                <Link to="/profile">My NFTs</Link>
              </li>              
              }  
              <li>
                <button className="enableEthereumButton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={connectWebsite}>{connected? "Connected":"Connect Wallet"}</button>
              </li>
            </ul>
          </li>
          </ul>
        </nav>
        <div className='text-white text-bold text-right mr-10 text-sm'>
          {currAddress !== "0x" ? "Connected to":"Not Connected. Please login to view NFTs"} {currAddress !== "0x" ? (currAddress.substring(0,15)+'...'):""}
        </div>
      </div>
    );
  }

  export default Navbar;