import Navbar from "./Navbar";
import NFTTile from "./NFTTile";
import { ethers } from 'ethers';
import SeidenwegeJSON from "../Seidenwege"
import axios from "axios";
import { useState } from "react";
import env from "react-dotenv";

export default function Marketplace() {
const [data, updateData] = useState([]);
const [dataFetched, setDataFetched] = useState(false);

async function getAllNFTs() {
    console.log("connecting to contract",SeidenwegeJSON.address)
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    //Pull the deployed contract instance
    let contract = new ethers.Contract(SeidenwegeJSON.address, SeidenwegeJSON.abi, signer)
    //create an NFT Token
    let transaction = await contract.getAllNFTs()

    //Fetch all the details of every NFT from the contract and display
    const items = await Promise.all(transaction.map(async i => {

        let tokenURI = await contract.tokenURI(i.tokenId);
        const owner = await contract.ownerOf(i.tokenId)
        if(tokenURI.indexOf("ipfs://")!==-1) tokenURI=env.IPFS_GATEWAY+"/"+tokenURI.substring(7,tokenURI.length)

        let meta = await axios.get(tokenURI);
        meta = meta.data;

        let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
        let item = {
            price,
            tokenId: i.tokenId.toNumber(),
            seller: i.seller,
            owner: owner,
            image: meta.image,
            name: meta.name,
            description: meta.description,
        }
        return item;
    }))

    setDataFetched(true);
    updateData(items);
}

if(!dataFetched)
    getAllNFTs();

return (
    <div>
        <Navbar></Navbar>
        <div className="flex flex-col place-items-center mt-20">
            <div className="md:text-xl font-bold text-white">
                All NFTs
            </div>
            <div className="flex mt-5 justify-between flex-wrap max-w-screen-xl text-center">
                {data.map((value, index) => {
                    return <div>
                        <NFTTile data={value} key={index}></NFTTile>
                            <div>NFT price: {value.price} {env?.BUY_NFT_CURRENCY}</div>
         {/*                   <div>
                                <button className="btn btn-blue" onClick={() => {}}>Buy NFT</button>
                            </div>
                            <div>
                                <button className="btn btn-blue" onClick={() => {}}>Buy T-Shirt</button>
                            </div>*/}
                        </div>
                })}
            </div>
        </div>            
    </div>
);

}